import { ChipProps } from '@mui/material'
import { TodoListItem } from 'components/TodoList'
import { TimelineEntryProps } from 'components/TimelineEntry'
import { StepPriority } from 'models/GuidelineStatement'
import { formatDateTime } from '@shared/utils/DateUtil'
import { formatTel } from '@shared/utils/TelUtil'
import {
  PatientAppPatient,
  ProviderSpecialty,
  StepType,
} from '__generated__/graphql'
import CareTeamMemberModel from './CareTeamMember'

export enum SpecialtyLabels {
  INTERNAL_MEDICINE = 'Internal Medicine',
  FAMILY_MEDICINE = 'Family Medicine',
  OBSTETRICS_GYNECOLOGY = 'Obstetrics and Gynecology',
  ONCOLOGY = 'Oncology',
  CARDIOLOGY = 'Cardiology',
  GASTROENTEROLOGY = 'Gastroenterology',
  SURGERY = 'Surgery',
  DERMATOLOGY = 'Dermatology',
  PSYCHIATRY = 'Psychiatry',
  OTHER = 'Other',
  UNKNOWN = 'Unknown',
}

export const specialtyOptions = Object.keys(ProviderSpecialty).map(
  (value) => ({
    label: SpecialtyLabels[value],
    value,
  }),
)

export enum Relationship {
  MOTHER = 'MOTHER',
  FATHER = 'FATHER',
  GRANDMOTHER = 'GRANDMOTHER',
  GRANDFATHER = 'GRANDFATHER',
  SISTER = 'SISTER',
  BROTHER = 'BROTHER',
  HALF_SISTER = 'HALF_SISTER',
  HALF_BROTHER = 'HALF_BROTHER',
  AUNT = 'AUNT',
  UNCLE = 'UNCLE',
  NIECE = 'NIECE',
  NEPHEW = 'NEPHEW',
  COUSIN_FEMALE = 'COUSIN_FEMALE',
  COUSIN_MALE = 'COUSIN_MALE',
  GRANDCHILD_FEMALE = 'GRANDCHILD_FEMALE',
  GRANDCHILD_MALE = 'GRANDCHILD_MALE',
  OTHER = 'OTHER',
}

export enum RelationshipLabels {
  MOTHER = 'Mother',
  FATHER = 'Father',
  GRANDMOTHER = 'Grandmother',
  GRANDFATHER = 'Grandfather',
  SISTER = 'Sister',
  BROTHER = 'Brother',
  HALF_SISTER = 'Half-sister',
  HALF_BROTHER = 'Half-brother',
  AUNT = 'Aunt',
  UNCLE = 'Uncle',
  NIECE = 'Niece',
  NEPHEW = 'Nephew',
  COUSIN_FEMALE = 'Cousin (female)',
  COUSIN_MALE = 'Cousin (male)',
  GRANDCHILD_FEMALE = 'Grandchild (female)',
  GRANDCHILD_MALE = 'Grandchild (male)',
  OTHER = 'Other',
}

export const relationshipOptions = Object.keys(Relationship).map(
  (value) => ({
    label: RelationshipLabels[value],
    value,
  }),
)

export enum CareStepCategory {
  MEDICAL_CARE = 'MEDICAL_CARE',
  SELF_CARE = 'SELF_CARE',
  OPTIONS_TO_CONSIDER = 'OPTIONS_TO_CONSIDER',
}

export type PatientCareStep = {
  title: string
  category: keyof typeof CareStepCategory
  priority: keyof typeof StepPriority
  type: keyof typeof StepType
  description?: string
  patientDescription?: string
  areaOfConcern: string
  id?: string
  metadata?: {
    careStepStories?: { slug: string; label?: string }[]
  }
  subtitle?: string
  storyId?: string
  chip?: {
    label?: ChipProps['label']
    icon?: ChipProps['icon']
    color?: ChipProps['color']
  }
}

export enum GeneVariantClassificationLabels {
  PATHOGENIC = 'Pathogenic',
  LIKELY_PATHOGENIC = 'Likely Pathogenic',
  VUS = 'Variant of Uncertain Significance',
}

export enum BiologicalSexLabels {
  FEMALE = 'Female',
  MALE = 'Male',
  OTHER = 'Other',
  UNKNOWN = 'Unknown',
}

export type PatientMocks = {
  todos: TodoListItem[]
  timeline: TimelineEntryProps[]
}

export default class PatientModel
  extends PatientAppPatient
  implements PatientMocks
{
  todos: TodoListItem[]
  timeline: TimelineEntryProps[]
  careTeam: CareTeamMemberModel[]

  constructor(
    data: Omit<
      PatientAppPatient,
      'patientConsent' | 'patientConsents'
    >,
    mocks: PatientMocks,
  ) {
    super()
    Object.assign(this, data, mocks, {
      careTeam:
        data.careTeamMembers?.map(
          (careTeamMember) => new CareTeamMemberModel(careTeamMember),
        ) ?? [],
    })
  }

  get fullName() {
    const { firstName, lastName } = this
    return `${firstName} ${lastName}`
  }

  get providerName() {
    return null
  }

  get formattedAddress() {
    if (!this.address) {
      return null
    }

    const { line1, line2, city, state, postalCode } = this.address

    return [
      [line1, line2].filter(Boolean).join(' '),
      `${city}, ${state} ${postalCode}`,
    ].join('\n')
  }

  get formattedBirthDate() {
    return formatDateTime(this.birthDate, false)
  }

  get formattedPhone() {
    return formatTel(this.phone)
  }

  get formattedBiologicalSex() {
    return BiologicalSexLabels[this.biologicalSex]
  }

  get formattedInsurance() {
    if (!this.insurance) {
      return null
    }

    return [
      this.insurance.carrierName,
      `ID: ${this.insurance.memberId}`,
      `Group: ${this.insurance.groupNumber}`,
    ].join('\n')
  }
}
