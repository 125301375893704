import React, { ElementType } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import JourneyIcon from 'assets/icons/road-straight.svg'
import UserIcon from 'assets/icons/user.svg'
import CarePlanIcon from 'assets/icons/task-list-approve.svg'
import TodosIcon from 'assets/icons/todos.svg'
import ShareIcon from 'assets/icons/task-list-share.svg'
import LearnIcon from 'assets/icons/read-human.svg'
import SupportIcon from 'assets/icons/help-wheel.svg'
import AppointmentIcon from 'assets/icons/appointments.svg'
import QuestionnaireIcon from 'assets/icons/task-list-edit.svg'
import ConsentDocumentIcon from 'assets/icons/task-list-check.svg'
import StepIcon from 'assets/icons/step.svg'
import GeneIcon from 'assets/icons/gene.svg'
import BookPlayIcon from 'assets/icons/book-play.svg'
import ButtonPlayIcon from 'assets/icons/button-play.svg'
import ControlsPlayIcon from 'assets/icons/controls-play.svg'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import CareTeamIcon from 'assets/icons/care-team.svg'
import LogoutIcon from 'assets/icons/logout.svg'
import DarkModeIcon from 'assets/icons/dark-mode.svg'
import LargeTextIcon from 'assets/icons/large-text.svg'
import QuestionIcon from 'assets/icons/question.svg'
import EmptyTasksImage from 'assets/images/empty-tasks.svg'
import DnaIcon from 'assets/icons/Dna--Streamline-Solar.svg'
import CleanImage from 'assets/images/clean.svg'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import MenuIcon from '@mui/icons-material/MenuRounded'
import BackIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'

export default function AppIcon(): JSX.Element {
  return null
}

function getAppIcon(IconType: ElementType) {
  return function AppIcon(props: SvgIconProps<'svg', unknown>) {
    return (
      <SvgIcon
        color="inherit"
        fontSize="small"
        {...props}
        component={IconType}
      />
    )
  }
}

AppIcon.Appointment = getAppIcon(AppointmentIcon)
AppIcon.ArrowForward = getAppIcon(ArrowForwardRoundedIcon)
AppIcon.ArrowBack = getAppIcon(ArrowBackRoundedIcon)
AppIcon.Back = getAppIcon(BackIcon)
AppIcon.BookPlay = getAppIcon(BookPlayIcon)
AppIcon.ButtonPlay = getAppIcon(ButtonPlayIcon)
AppIcon.CarePlan = getAppIcon(CarePlanIcon)
AppIcon.CareTeam = getAppIcon(CareTeamIcon)
AppIcon.Check = getAppIcon(CheckCircleRoundedIcon)
AppIcon.ChevronRight = getAppIcon(ChevronRightIcon)
AppIcon.Clean = getAppIcon(CleanImage)
AppIcon.Close = getAppIcon(CloseIcon)
AppIcon.ConsentDocument = getAppIcon(ConsentDocumentIcon)
AppIcon.ControlsPlay = getAppIcon(ControlsPlayIcon)
AppIcon.DarkMode = getAppIcon(DarkModeIcon)
AppIcon.EmptyTasks = getAppIcon(EmptyTasksImage)
AppIcon.Gene = getAppIcon(GeneIcon)
AppIcon.Journey = getAppIcon(JourneyIcon)
AppIcon.LargeText = getAppIcon(LargeTextIcon)
AppIcon.Learn = getAppIcon(LearnIcon)
AppIcon.Logout = getAppIcon(LogoutIcon)
AppIcon.Menu = getAppIcon(MenuIcon)
AppIcon.Questionnaire = getAppIcon(QuestionnaireIcon)
AppIcon.Share = getAppIcon(ShareIcon)
AppIcon.Step = getAppIcon(StepIcon)
AppIcon.Support = getAppIcon(SupportIcon)
AppIcon.TestResult = getAppIcon(AssignmentOutlinedIcon)
AppIcon.Todos = getAppIcon(TodosIcon)
AppIcon.User = getAppIcon(UserIcon)
AppIcon.Question = getAppIcon(QuestionIcon)
AppIcon.Play = getAppIcon(PlayArrowRoundedIcon)
AppIcon.OpenInNew = getAppIcon(OpenInNewRoundedIcon)
AppIcon.GeneticTesting = getAppIcon(DnaIcon)
AppIcon.Summary = getAppIcon(ListAltRoundedIcon)
