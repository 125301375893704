import React, { WheelEvent, useState } from 'react'
import { InputAdornment } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import StoryFieldLabel from 'components/StoryFieldLabel'
import { NumberField } from 'components/StoryNumberField'
import {
  FieldProps,
  FormValues,
} from 'components/StoryPlayer/pages/PageForm'
import {
  convertKgToLb,
  convertLbToKg,
} from '@shared/utils/UnitConversionUtil'
import HelperBlocks from 'components/HelperBlocks'
import { Controller, UseFormReturn } from 'react-hook-form'
import { isUndefined, omit } from 'lodash'
import { isValidNumber, parseNumberString } from 'utils/FormUtil'
import UnitSelectMenu from 'components/UnitSelectMenu'

export enum WeightUnit {
  lb = 'lb',
  kg = 'kg',
}
export type WeightValues = {
  kg: number | ''
  lb: number | ''
}

export type WeightField = Omit<
  NumberField,
  'type' | 'startAdornment' | 'endAdornment'
> & {
  type: 'weight'
  unit?: keyof typeof WeightUnit
}

const defaultMaxInKg = 317.515 // 700 lb
const defaultMax = {
  kg: Math.ceil(defaultMaxInKg),
  lb: Math.ceil(convertKgToLb(defaultMaxInKg)),
}

export type StoryWeightFieldProps = Omit<FieldProps, 'value'> &
  WeightField & {
    value: number | ''
    disabled?: boolean
    formContext: UseFormReturn<FormValues>
  }

export default function StoryWeightField(
  props: StoryWeightFieldProps,
) {
  const {
    name,
    formContext,
    fieldState,
    label,
    required,
    min = 0,
    max,
    value: valueInKg,
    onChange,
    helperBlocks,
    disableRequiredAsterisk,
    inputRef,
  } = props
  const minValue = {
    kg: Math.floor(min),
    lb: Math.floor(convertKgToLb(min)),
  }
  const maxValue = isUndefined(max)
    ? defaultMax
    : { kg: Math.ceil(max), lb: Math.ceil(convertKgToLb(max)) }
  const getMaxErrorMessage = (unit: WeightField['unit']) => {
    const label = {
      kg: `${maxValue.kg} kg`,
      lb: `${maxValue.lb} lb`,
    }[unit]
    return `Must be less than ${label}`
  }
  const { control, clearErrors } = formContext
  const [values, setValues] = useState({
    kg: String(valueInKg),
    lb:
      valueInKg === '' ? valueInKg : String(convertKgToLb(valueInKg)),
  })
  const [unit, setUnit] = useState<WeightField['unit']>(
    props.unit ?? 'lb',
  )
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>(null)
  const placeholder =
    props.placeholder ?? `${minValue[unit]}-${maxValue[unit]}`
  const valueForUnit = values[unit]
  const error = fieldState.error
  const helperText = error?.message || props.helperText

  return (
    <FormControl fullWidth error={!!error} sx={{ gap: 1 }}>
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      />
      <HelperBlocks blocks={helperBlocks} />
      <Controller
        name={name}
        control={control}
        defaultValue={isValidNumber(values.kg) ? values.kg : ''}
        rules={{
          max: {
            message: getMaxErrorMessage(unit),
            value: maxValue.kg,
          },
          min: minValue.kg,
          required,
        }}
        render={({ field }) => (
          <OutlinedInput
            {...omit(field, 'ref')}
            inputRef={inputRef}
            color="secondary"
            inputProps={{
              inputMode: 'numeric',
              onWheel: (e: WheelEvent<HTMLInputElement>) =>
                e.currentTarget.blur(),
            }}
            type="text"
            placeholder={placeholder}
            autoFocus={props.autoFocus}
            onBlur={props.onBlur}
            onChange={(e) => {
              clearErrors()
              const value = parseNumberString(e.target.value)
              const kg =
                unit === 'kg'
                  ? parseFloat(value)
                  : convertLbToKg(parseFloat(value))
              const lb = convertKgToLb(kg)
              const isValid =
                !!kg &&
                isFinite(Number(kg)) &&
                !/\.$/.test(String(kg))

              setValues({
                kg: unit === 'kg' ? value : String(kg),
                lb: unit === 'lb' ? value : String(lb),
              })
              onChange(isValid ? kg : '')
            }}
            value={isValidNumber(valueForUnit) ? valueForUnit : ''}
            fullWidth
            disabled={props.disabled}
            endAdornment={
              <InputAdornment position="end" sx={{ mr: -1.5 }}>
                <UnitSelectMenu<WeightField['unit']>
                  value={unit}
                  anchorEl={menuAnchorEl}
                  onAnchorElChange={setMenuAnchorEl}
                  onChange={(value) => {
                    clearErrors()
                    setUnit(value)
                  }}
                  options={Object.values(WeightUnit)}
                />
              </InputAdornment>
            }
            error={!!error}
            sx={{
              '& .MuiInputBase-input': {
                textAlign: 'center',
              },
            }}
          />
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
