import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Drawer from '@mui/material/Drawer'
import StoryPage, {
  StoryPageData,
} from 'components/StoryPlayer/StoryPage'
import StoryPlayerHeader from 'components/StoryPlayerHeader'
import {
  Box,
  Button,
  Grow,
  Stack,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { SystemStyleObject } from '@mui/system/styleFunctionSx'
import { FormField } from 'components/StoryPlayer/pages/PageForm'
import { EventHandler, RuleProperties } from 'json-rules-engine'
import { useDebounce, useDebouncedValue } from 'rooks'
import useInterpolate, {
  useInterpolationData,
} from 'hooks/useInterpolate'
import RuleEngine from 'components/RuleEngine'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { nth } from 'lodash'
import { BackgroundColor } from 'components/NestMuiTheme'
import usePageTheme from 'hooks/usePageTheme'
import SvgBlock from 'components/blocks/SvgBlock'
import {
  AdditionalPageEvent,
  DynamicChapterNavigationEvent,
  StoryEvent,
  StoryNavigationEvent,
} from 'components/StoryPlayer/StoryEvents'
import { getModuleStory } from 'components/StoryPlayer/Modules'
import { useStoryPages } from 'components/StoryPlayerUtil'
import { QuestionnaireResponseItemInput } from '__generated__/graphql'
import { JSONSchema7 } from 'json-schema'
import useStorySettings from 'hooks/useStorySettings'
import { formatAge } from '@shared/utils/DateUtil'
import ErrorSnackbar from 'components/ErrorSnackbar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import { useDynamicChapter } from 'hooks/useDynamicChapter'

export type StoryConnectionRule = Omit<
  RuleProperties,
  'onSuccess' | 'onFailure'
> & {
  event: StoryNavigationEvent | DynamicChapterNavigationEvent
}

export type StoryAdditionalRule = Omit<
  RuleProperties,
  'onSuccess' | 'onFailure'
> & {
  event: AdditionalPageEvent
}

export enum StoryScope {
  'learn' = 'learn',
  'profile' = 'profile',
  'module' = 'module',
}

export type StorySxProps = SystemStyleObject<Theme>
export type StoryTheme = {
  background?: string
  backgroundColor?: string
  color?: string
  secondaryColor?: string
  contrastThreshold?: number
}

export type StoryData = {
  id: string
  contentAccountId: string
  title?: string
  category?: string
  scope: keyof typeof StoryScope
  dateModified: string
  storySettingsSchemaProperties: JSONSchema7['properties']
  pages: StoryPageData[]
  sx?: StorySxProps
  disableClose?: boolean
  enableActionIconButton?: boolean
  theme?: StoryTheme
} & (
  | {
      initialPageId: string
      initialConnectionRules?: never
    }
  | {
      initialPageId?: never
      initialConnectionRules: StoryConnectionRule[] // Used for module chapters.
    }
)

const getDefaultConnectionRule = (
  pageId: string,
): StoryConnectionRule => {
  if (!pageId) {
    // Skip rule for no pageId.
    return null
  }
  return {
    // Arbitrary rule that will always be true.
    conditions: { any: [] },
    event: {
      params: { pageId },
      type: 'StoryNavigation',
    },
    name: 'Default',
  }
}

const getConnectionRules = async (
  defaultConnectionPageId: string,
  pageConnectionRules: StoryConnectionRule[],
): Promise<StoryConnectionRule[]> =>
  [
    getDefaultConnectionRule(defaultConnectionPageId),
    ...(await Promise.all(
      (pageConnectionRules ?? []).map(async (rule) => {
        const moduleStory = getModuleStory(rule.event.params.module)
        if (moduleStory) {
          // Set connection rule event pageId to module's initial pageId.
          rule.event.params.pageId = moduleStory.initialPageId
        }

        return {
          ...rule,
          priority: rule.priority ?? 2,
        }
      }),
    )),
  ].filter(Boolean)

const handleDebugSuccess: EventHandler = (
  event,
  almanac,
  ruleResult,
) => {
  if (localStorage.getItem('debugEnabled')) {
    console.log('debug', 'event success', event, ruleResult)
  }
}

export const getStoryParamsFromPath = (url = '') => {
  const matches = url?.match?.(/#\/story\/?([\w-]+)\/?([\w-]+)?/)
  return {
    pageId: matches?.[2],
    storyId: matches?.[1],
  }
}

export const getStoryUrl = (storyId: string, pageId?: string) => {
  return `#/story/${storyId}${(pageId && `/${pageId}`) ?? ''}`
}

export const playerMaxWidth = 480
export const playerMaxHeight = (16 / 9) * playerMaxWidth

export type StoryPlayerApi = {
  setPageId: (pageId: string) => void
}

export type StoryPlayerProps = {
  open: boolean
  story: StoryData
  onStoryComplete: (story: StoryData, nextStoryId: string) => void
  onPageNext: (storyId: string, pageId: string) => Promise<void>
  onPageBack: (storyId: string, pageId: string) => void
  onPageChange?: (storyId: string, pageId: string) => void
  onStoryEvent: (
    event: StoryEvent,
    args: {
      parseEventValue: (value: unknown) => string
      questionnaireResponses: Record<string, unknown>
      questionnaireResponseItems: QuestionnaireResponseItemInput[]
    },
  ) => Promise<void>
  onSubmit: (
    storyId: string,
    data: Record<string, unknown>,
    fields: FormField[],
    story: StoryData,
  ) => Promise<Record<string, unknown>>
  onInitialPageMount?: (storyId: string, pageId: string) => void
  onExited: () => void
  onClose: () => void
}

const StoryPlayer = React.forwardRef(function StoryPlayer(
  props: StoryPlayerProps,
  ref: MutableRefObject<StoryPlayerApi>,
) {
  const {
    onPageNext,
    onPageBack,
    onPageChange,
    onExited,
    story,
    open,
    onInitialPageMount,
    onClose,
    onStoryEvent,
    onStoryComplete,
  } = props
  const [hiddenTitle, setHiddenTitle] = useState(false)
  const storyCompletedRef = useRef(false)
  const {
    patient,
    patientData,
    questionnaireResponseItems,
    questionnaireResponses,
    storyProgress,
    hiddenControls,
    refetchPatientData,
    setHiddenControls,
    setStoryPages,
  } = useContext(StoryPlayerContext)
  const interpolate = useInterpolate(StoryPlayerContext)
  const interpolationData = useInterpolationData(StoryPlayerContext)
  const storyProgressHistory = storyProgress?.getHistory(story?.id)
  const history = useMemo(
    () => storyProgressHistory ?? [],
    [storyProgressHistory],
  )
  const [pageId, setPageId] = useState(
    nth(history, -1) ?? story?.initialPageId,
  )
  const storyPages = useStoryPages(story, interpolationData)
  const page = storyPages.find(
    ({ id }) => id === (pageId ?? story?.initialPageId),
  )
  const backgroundColor =
    page?.type === 'video'
      ? // Override video pages with black background.
        '#000'
      : (page?.theme?.backgroundColor ??
        story?.theme?.backgroundColor ??
        BackgroundColor.light.default)
  const pageTheme = usePageTheme({
    backgroundColor,
    color: page?.theme?.color ?? story?.theme?.color,
    contrastThreshold: story?.theme?.contrastThreshold,
    secondaryColor:
      page?.theme?.secondaryColor ?? story?.theme?.secondaryColor,
  })
  const [showNotFound, immediatelySetShowNotFound] =
    // Long enough for the story to be loaded and checked for a page.
    // If nothing is found in 1 second then show the not found page.
    useDebouncedValue(!page, 1000)
  const questionnaireResponsesData = useRef(questionnaireResponses)
  const {
    storySettings: accountStorySettings,
    errorMessage: accountStorySettingsErrorMessage,
  } = useStorySettings(
    story?.storySettingsSchemaProperties,
    patient?.account?.storySettings,
  )
  const dynamicChapter = useDynamicChapter()
  const getRuleEngineData = useCallback(() => {
    const ruleEnginePatientData = patientData.reduce(
      (acc, { fieldName, value }) => {
        acc[fieldName] = value
        return acc
      },
      {},
    )
    return {
      account: patient?.account ?? {},
      accountStorySettings,
      patient: {
        ...patient,
        age: formatAge(patient.birthDate),
        mostRecentCareStepOccurrences: (
          patient.mostRecentCareStepOccurrences ?? []
        ).reduce((acc, item) => {
          acc[item.careStepCodeId] = item
          return acc
        }, {}),
      },
      patientData: ruleEnginePatientData,
      questionnaireResponses: questionnaireResponsesData.current,
    }
  }, [patient, patientData, accountStorySettings])
  const handleAdditionalRules = useCallback(
    async (
      additionalRules: StoryAdditionalRule[],
      ruleEngineData: unknown,
    ) => {
      const interpolatedAdditionalRules = additionalRules
        ? JSON.parse(
            interpolate(JSON.stringify(additionalRules), {
              questionnaireResponses:
                questionnaireResponsesData.current,
            }),
          )
        : null
      const additionalEngineResult = await new RuleEngine(
        interpolatedAdditionalRules ?? [],
        { allowUndefinedFacts: true },
      )
        .on('success', handleDebugSuccess)
        .run(ruleEngineData)
      const parseEventValue = (value: unknown) => {
        const interpolatedValue = interpolate(JSON.stringify(value), {
          questionnaireResponses: questionnaireResponsesData.current,
        })
        let result = ''
        try {
          result = JSON.parse(interpolatedValue)
        } catch (e) {
          console.error(e)
          result = ''
        }

        return result
      }

      // Handle events from additional rules.
      await Promise.all(
        additionalEngineResult.results.map(({ event }) =>
          onStoryEvent(event as StoryEvent, {
            parseEventValue,
            questionnaireResponseItems,
            questionnaireResponses:
              questionnaireResponsesData.current,
          }),
        ),
      )
    },
    [interpolate, onStoryEvent, questionnaireResponseItems],
  )
  const handleConnectionRules = useCallback(
    async (
      connectionRules: StoryConnectionRule[],
      ruleEngineData: Record<string, unknown>,
      options?: { skipDynamicChapters?: boolean },
    ): Promise<{
      story: StoryData
      nextStoryId: string
      nextPageId: string
      hasConnection: boolean
      storyCompleted: boolean
    }> => {
      const { skipDynamicChapters } = options ?? {}
      const interpolatedConnectionRules = connectionRules
        ? JSON.parse(
            interpolate(JSON.stringify(connectionRules), {
              questionnaireResponses:
                questionnaireResponsesData.current,
            }),
          )
        : null
      const connectionEngineResult = await new RuleEngine(
        interpolatedConnectionRules,
      )
        .on('success', handleDebugSuccess)
        .run(ruleEngineData)
      const navigationResult = connectionEngineResult.results.find(
        ({ event }) =>
          ['StoryNavigation', 'DynamicChapterNavigation'].includes(
            (event as StoryEvent).type,
          ),
      )
      const event = navigationResult?.event as StoryEvent
      const params = (event as StoryNavigationEvent)?.params
      const { pageId, storyId } = params ?? {}
      const storyCompleted =
        // Story is completed if no next page or story changed.
        !pageId || (!!storyId && story?.id !== storyId)
      const hasConnection = !!(pageId || storyId)

      // Handle dynamic chapter navigation connections.
      if (event?.type === 'DynamicChapterNavigation') {
        if (skipDynamicChapters) {
          // Assume pages with dynamic chapters are never the last story page.
          return {
            hasConnection: true,
            nextPageId: event.params.returnToPageId,
            nextStoryId: story?.id,
            story,
            storyCompleted: false,
          }
        }

        const pages = await dynamicChapter.getPages(
          event.params,
          questionnaireResponsesData,
        )
        const pageIds = pages.map(({ id }) => id)
        const nextPageId = pages[0]?.id ?? event.params.returnToPageId

        // Add dynamic chapter pages to current story pages.
        setStoryPages([
          ...storyPages.filter(({ id }) => !pageIds.includes(id)),
          ...pages,
        ])

        return {
          hasConnection: true,
          nextPageId,
          nextStoryId: story?.id,
          story,
          storyCompleted: false,
        }
      }

      if (event?.type === 'StoryNavigation') {
        const { module } = event.params

        // Handle module story connections.
        if (module) {
          const moduleStory = getModuleStory(module)
          if (!moduleStory) {
            throw new Error(
              `Module story "${module.type}" not found.`,
            )
          }

          // Handle story navigation connections.
          return {
            hasConnection: true,
            nextPageId: moduleStory?.initialPageId,
            nextStoryId: story?.id,
            story,
            storyCompleted: false,
          }
        }

        // Handle story navigation connections.
        return {
          hasConnection,
          nextPageId: pageId,
          nextStoryId: storyId || story?.id,
          story,
          storyCompleted,
        }
      }

      if (!storyCompleted) {
        console.error('Unhandled story connection', {
          connectionRules,
          navigationResult,
          storyCompleted,
        })
      }

      // Handle story completed and unhandled connections.
      return {
        hasConnection,
        nextPageId: pageId,
        nextStoryId: storyId || story?.id,
        story,
        storyCompleted,
      }
    },
    [dynamicChapter, interpolate, setStoryPages, story, storyPages],
  )
  const handleNextConnection = useCallback(
    async (nextStoryId: string, nextPageId: string) => {
      storyProgress.pushHistory(nextStoryId, nextPageId)
      setPageId(nextPageId)
      await onPageNext(nextStoryId, nextPageId)
      onPageChange?.(nextStoryId, nextPageId)
    },
    [onPageChange, onPageNext, storyProgress],
  )
  const handleBack = () => {
    const history = storyProgress.popHistory(story?.id) ?? []
    const prevPageId = nth(history, -1)
    if (prevPageId) {
      setPageId(prevPageId)
      onPageBack(story?.id, prevPageId)
      onPageChange?.(story?.id, prevPageId)
    }
  }
  const handleStoryCompleted = useCallback(async () => {
    const ruleEngineData = getRuleEngineData()
    const { storyCompleted, story, nextStoryId } =
      await handleConnectionRules(
        await getConnectionRules(
          page?.defaultConnectionPageId,
          page?.connectionRules,
        ),
        ruleEngineData,
        // Dynamic chapters not needed to check for story completion.
        { skipDynamicChapters: true },
      )

    if (storyCompleted && !storyCompletedRef.current) {
      await handleAdditionalRules(
        page?.additionalRules,
        ruleEngineData,
      )
      onStoryComplete(story, nextStoryId)
      storyCompletedRef.current = true
    }
  }, [
    getRuleEngineData,
    handleAdditionalRules,
    handleConnectionRules,
    onStoryComplete,
    page?.additionalRules,
    page?.connectionRules,
    page?.defaultConnectionPageId,
  ])
  const handleStoryCompletedDebounced = useDebounce(
    // Debounce prevents calling `onStoryComplete` with incorrect state
    // when query params are updated during story to story navigation.
    handleStoryCompleted,
    100,
  )
  const [loading, setLoading] = useState(false)
  const loadDynamicChapterPages = async (page: StoryPageData) => {
    // Use handleConnectionRules for loading dynamic chapter pages.
    const ruleEngineData = getRuleEngineData()
    await handleConnectionRules(
      await getConnectionRules(
        page?.defaultConnectionPageId,
        page?.connectionRules,
      ),
      ruleEngineData,
    )
    setLoading(false)
  }
  const loadDynamicChapterPagesDebounced = useDebounce(
    loadDynamicChapterPages,
    100,
  )

  // Load dynamic chapter pages if they exist in page history.
  useEffect(() => {
    const missingPageIndex = history.findIndex(
      (id) => !storyPages.map(({ id }) => id).includes(id),
    )
    // Assume pages missing from history are dynamic chapter pages.
    const dynamicChapterPage = storyPages.find(
      ({ id }) => id === history[missingPageIndex - 1],
    )

    if (dynamicChapterPage) {
      setLoading(true)
      loadDynamicChapterPagesDebounced(dynamicChapterPage)
    }
  }, [history, loadDynamicChapterPagesDebounced, storyPages])

  useEffect(() => {
    if (story?.id) {
      storyCompletedRef.current = false
      refetchPatientData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [story?.id])

  // Handle story completed on page change.
  useEffect(() => {
    if (!page) {
      return
    }
    handleStoryCompletedDebounced()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  // Set ref for StoryPlayerApi.
  useEffect(() => {
    if (ref && !ref.current) {
      ref.current = {
        setPageId,
      }
    }
  }, [ref])

  // Sync questionnaire responses data changes.
  useEffect(() => {
    questionnaireResponsesData.current = questionnaireResponses
  }, [questionnaireResponses])

  useEffect(() => {
    if (storyProgress && open && story) {
      const history = storyProgress?.getHistory(story?.id) ?? []
      // Page isn't in history so use initial page.
      if (!history.length || !history.includes(pageId)) {
        const startPageId = nth(history, -1) ?? story.initialPageId
        setPageId(startPageId)
        storyProgress.pushHistory(story.id, startPageId)
        if (story.id && startPageId) {
          onInitialPageMount?.(story.id, startPageId)
        }
      }

      // Story resumes to first occurrence of page in history.
      if (history.includes(pageId)) {
        const resumedHistory =
          storyProgress.updateHistory(story.id, pageId) ?? []
        const startPageId =
          nth(resumedHistory, -1) ?? story.initialPageId
        setPageId(startPageId)
      }
    }
  }, [
    open,
    pageId,
    story,
    story?.id,
    story?.initialPageId,
    storyProgress,
    onInitialPageMount,
  ])

  // Immediately hide not found page if page is available.
  useEffect(() => {
    if (page) {
      immediatelySetShowNotFound(false)
    }
  }, [immediatelySetShowNotFound, page])

  return (
    <ThemeProvider theme={pageTheme}>
      <Drawer
        sx={{
          '& > .MuiBackdrop-root': {
            backdropFilter: 'blur(5px) grayscale(.5)',
            bgcolor: 'rgba(0,0,0,.8)',
          },
          flexShrink: 0,
          height: '100vh',
          width: '100vw',
          zIndex: 1201,
        }}
        anchor="bottom"
        PaperProps={{
          sx: {
            backgroundImage: 'none',
            bgcolor: page ? 'transparent' : 'background.default',
            borderRadius: 2,
            height: 1,
            m: 'auto',
            maxHeight: playerMaxHeight,
            maxWidth: playerMaxWidth,
            top: {
              sm: 0,
            },
            willChange: 'transform',
            ...story?.sx,
          } as SxProps,
        }}
        open={open}
        ModalProps={{ keepMounted: true }}
        SlideProps={{
          onExited: () => {
            setPageId(null)
            onExited()
          },
        }}
        disableRestoreFocus
        onClose={(_, reason) => {
          if (!['backdropClick', 'escapeKeyDown'].includes(reason)) {
            onClose()
          }
        }}
      >
        <StoryPlayerHeader
          title={interpolate(
            page?.overrideHeaderTitle ?? story?.title,
          )}
          category={story?.category}
          pageId={pageId}
          pages={storyPages}
          history={history}
          hasVideoMuteButton={
            page?.type === 'video' && !page.noControls
          }
          hasAudioButton={!!page?.audioSrc}
          audioSrc={page?.audioSrc}
          onBack={handleBack}
          onClose={onClose}
          backgroundColor={backgroundColor}
          hidden={hiddenControls}
          hiddenTitle={page?.type === 'story-start' || hiddenTitle}
          disableBack={
            story?.initialPageId === pageId ||
            page?.disableBack === true
          }
          disableClose={story?.disableClose}
          disableProgress={
            page?.type === 'story-start' || page?.disableProgress
          }
        />
        {page && (
          <StoryPage
            {...page}
            setHiddenTitle={setHiddenTitle}
            enableActionIconButton={
              (story?.enableActionIconButton ||
                page?.enableActionIconButton) &&
              page?.enableActionIconButton !== false
            }
            hiddenControls={hiddenControls}
            onHideControls={setHiddenControls}
            onClose={onClose}
            onNext={async () => {
              const ruleEngineData = getRuleEngineData()
              setLoading(true)
              await handleAdditionalRules(
                page?.additionalRules,
                ruleEngineData,
              )
              const { hasConnection, nextStoryId, nextPageId } =
                await handleConnectionRules(
                  await getConnectionRules(
                    page?.defaultConnectionPageId,
                    page?.connectionRules,
                  ),
                  ruleEngineData,
                )
              setLoading(false)
              // Navigate to the resulting story and page.
              if (hasConnection) {
                await handleNextConnection(nextStoryId, nextPageId)
              }
            }}
            gotoPage={(pageId: string) => {
              handleNextConnection(story.id, pageId)
            }}
            onBack={handleBack}
            onSubmit={async (data, fields) => {
              questionnaireResponsesData.current =
                await props.onSubmit(story.id, data, fields, story)
            }}
            loading={loading}
          />
        )}
        {!page && (
          <Grow
            in={showNotFound && !!storyPages.length && !loading}
            mountOnEnter
            unmountOnExit
          >
            <Stack
              sx={{
                alignItems: 'center',
                flexGrow: 1,
                gap: 2,
                justifyContent: 'center',
                mt: -10,
              }}
            >
              <SvgBlock src="/img/not-found.svg" />
              <Stack sx={{ alignItems: 'center', gap: 2 }}>
                <Stack alignItems="center">
                  <Typography variant="h4">Page not found</Typography>
                  <Typography variant="caption">
                    <code>{pageId}</code>
                  </Typography>
                </Stack>
                <Stack
                  sx={{ gap: 2, maxWidth: 240, textAlign: 'center' }}
                >
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => {
                      storyProgress.clearHistory(story?.id)
                      setPageId(story?.initialPageId)
                    }}
                  >
                    Restart Story
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grow>
        )}
        <LoadingBackdrop open={loading} invisible />
      </Drawer>
      <ErrorSnackbar
        open={!!accountStorySettingsErrorMessage}
        onClose={null}
        title="Misconfigured Story Settings"
      >
        <Box
          component="pre"
          sx={{ fontFamily: 'monospace', fontSize: '.825em', px: 2 }}
        >
          {accountStorySettingsErrorMessage}
        </Box>
      </ErrorSnackbar>
    </ThemeProvider>
  )
})

export default StoryPlayer
