import { useAuth } from '@frontegg/react'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import {
  QuestionnaireResponseItemInput,
  QuestionnaireResponseItemType,
  TodoCompleteActionType,
  useQuestionnaireResponseLazyQuery,
  useTodoCompleteActionMutation,
  useUpsertQuestionnaireResponseMutation,
} from '__generated__/graphql'
import { AppContext } from 'components/AppContext'
import ConsentDialog from 'components/ConsentDialog'
import MainNavBar from 'components/MainNavBar'
import { mixins } from 'components/NestMuiTheme'
import StoryPlayer, {
  StoryData,
  StoryPlayerApi,
  getStoryParamsFromPath,
  getStoryUrl,
} from 'components/StoryPlayer'
import { FormField } from 'components/StoryPlayer/pages/PageForm'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import {
  flattenQuestionnaireResponse,
  getAnswerLabel,
} from 'components/StoryPlayerUtil'
import useAutoLogout from 'hooks/useAutoLogout'
import useRouteCheck from 'hooks/useRouteCheck'
import { nth, omit, upperCase } from 'lodash'
import { useRouter } from 'next/router'
import LoginAgain from 'components/LoginAgain'
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { usePreviousDifferent } from 'rooks'
import { Environment } from 'types/Global'
import { hasPatientRole } from 'utils/UserUtil'
import SessionExpiredDialog from './AutoLogout/SessionExpiredDialog'
import SessionInactiveDialog from './AutoLogout/SessionInactiveDialog'
import PendoScript from './PendoScript'
import { TrackerContext } from './TrackerContext'
import KioskSpeedDial from 'components/KioskSpeedDial'
import useStoryEvents from 'hooks/useStoryEvents'
import AppointmentDialog from 'components/AppointmentDialog'
import DocumentDialog from 'components/DocumentDialog'

const { COMPLETE_STORY } = TodoCompleteActionType

const ACTIVITY_EXPIRATION =
  process.env.NODE_ENV === Environment.DEVELOPMENT
    ? 3600000 * 12 // 12 hours (development)
    : 60000 * 30 // 30 min (staging and production)

export default function AppBase(props: { children: ReactNode }) {
  const { ready, patient, stories, theme, hasBackNavigation } =
    useContext(AppContext)
  const {
    storyProgress,
    storyId,
    pageId,
    storyPlayerOpen,
    setStoryPlayerOpen,
    setQuestionnaireResponseItems,
  } = useContext(StoryPlayerContext)
  const { isAuthenticated, user } = useAuth()
  const router = useRouter()
  const prevStoryId = usePreviousDifferent(storyId)
  const prevPageId = usePreviousDifferent(pageId)
  const storyPlayerApiRef = useRef<StoryPlayerApi>(null)
  const [upsertQuestionnaireResponse] =
    useUpsertQuestionnaireResponseMutation()
  const { handleStoryEvent } = useStoryEvents()
  const { isAuthRequired, isUploadedContent } = useRouteCheck()
  // Authenticated user with no patient record.
  const hasValidPatient = isAuthRequired
    ? isAuthenticated && ready && patient
    : true
  const [todoCompleteActionMutation] = useTodoCompleteActionMutation()
  const [fetchQuestionnaireResponse] =
    useQuestionnaireResponseLazyQuery()
  const handleStoryCompleted = useCallback(
    async (story: StoryData) => {
      if (!story) {
        return
      }
      await todoCompleteActionMutation({
        variables: {
          todoCompleteActionInput: {
            completeActionData: {
              storyId: story.id,
            },
            completeActionType: COMPLETE_STORY,
          },
        },
      })
      storyProgress.setCompleted(story.id)
    },
    [storyProgress, todoCompleteActionMutation],
  )
  const isPatientUser = hasPatientRole(user)
  const {
    logout,
    sessionIsExpired,
    sessionIsInactive,
    setSessionIsInactive,
  } = useAutoLogout(
    ACTIVITY_EXPIRATION,
    !isAuthRequired && !isAuthenticated && !isPatientUser,
  )
  const { initTracker } = useContext(TrackerContext)
  const activeStory = stories?.find(({ id }) => id === storyId)
  const routerReplaceTimeout = useRef(-1)
  const fetchQuestionnaireResponseByStoryId = useCallback(
    async (storyId: string) => {
      if (!storyId) {
        return
      }
      // Fetch latest questionnaire responses.
      const result = await fetchQuestionnaireResponse({
        variables: { name: storyId },
      })
      const questionnaireResponse =
        result.data?.patientApp?.questionnaireResponse
      const items = questionnaireResponse?.items ?? []

      setQuestionnaireResponseItems(items)
    },
    [fetchQuestionnaireResponse, setQuestionnaireResponseItems],
  )
  const handleInitialPageMount = useCallback(
    (storyId: string, pageId: string) => {
      window.clearTimeout(routerReplaceTimeout.current)
      routerReplaceTimeout.current = window.setTimeout(() => {
        router?.replace(getStoryUrl(storyId, pageId))
      }, 500)
    },
    [router],
  )

  // Initialize open relay tracker.
  useEffect(() => {
    initTracker()
  }, [initTracker])

  // Manage story player `open` state.
  useEffect(() => {
    setStoryPlayerOpen(!!storyId)
  }, [setStoryPlayerOpen, storyId])

  // Fetch initial questionnaire response on story change.
  useEffect(() => {
    fetchQuestionnaireResponseByStoryId(storyId)
  }, [fetchQuestionnaireResponseByStoryId, storyId])

  // Update history on story page changes.
  useEffect(() => {
    if (storyProgress && storyId && storyPlayerApiRef.current) {
      const history = storyProgress?.getHistory(storyId) ?? []
      const pageIndex = history.indexOf(pageId)

      if (history.length && pageIndex === -1) {
        const lastHistoryPageId = nth(history, -1)
        storyPlayerApiRef.current.setPageId(lastHistoryPageId)
        window.clearTimeout(routerReplaceTimeout.current)
        routerReplaceTimeout.current = window.setTimeout(() => {
          router?.replace(getStoryUrl(storyId, lastHistoryPageId))
        }, 500)
      } else {
        storyPlayerApiRef.current.setPageId(pageId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevStoryId, prevPageId, storyId, pageId, storyProgress])

  if (!ready) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {isAuthRequired &&
        isPatientUser &&
        hasValidPatient &&
        !isUploadedContent && (
          <MainNavBar
            showLogo={router.route === '/'}
            hasBack={hasBackNavigation}
          />
        )}
      {isAuthRequired && !hasValidPatient && (
        <LoginAgain>Patient does not exist.</LoginAgain>
      )}
      {(!isAuthRequired || hasValidPatient) && (
        <>
          <Box
            sx={{
              ...mixins.responsiveWidth,
            }}
          >
            {props.children}
          </Box>
          <StoryPlayer
            ref={storyPlayerApiRef}
            open={storyPlayerOpen}
            story={activeStory}
            onStoryComplete={handleStoryCompleted}
            onInitialPageMount={handleInitialPageMount}
            onPageNext={async (storyId, pageId) => {
              await router.push(getStoryUrl(storyId, pageId))
            }}
            onPageBack={(storyId, pageId) => {
              router.replace(getStoryUrl(storyId, pageId))
            }}
            onStoryEvent={handleStoryEvent}
            // Using path `#/` prevents body from scrolling to top.
            onExited={() => {
              const { storyId } = getStoryParamsFromPath(
                window.location.hash,
              )
              // Only clear hash params if story hash param exists.
              if (storyId) {
                router.push('#/')
              }
            }}
            onSubmit={async (name, data, fields, story) => {
              const getField = (name: string) =>
                fields.find(
                  (field) => field.name === name,
                ) as FormField

              // Persist form data as questionnaire response.
              const result = await upsertQuestionnaireResponse({
                variables: {
                  name,
                  upsertQuestionnaireResponseInput: {
                    items: Object.entries(data)
                      .filter(
                        ([key]) =>
                          // Filter only fields that are defined in the page form fields.
                          !!fields.find(({ name }) => name === key),
                      )
                      .map(
                        ([
                          key,
                          value,
                        ]): QuestionnaireResponseItemInput => ({
                          answer: value,
                          answerLabel: getAnswerLabel(
                            key,
                            value,
                            fields,
                          ),
                          label: getField(key).label ?? key,
                          // Persist additional field data (e.g. options, required, etc).
                          metadata: omit(getField(key), [
                            'label',
                            'name',
                            'type',
                          ]),
                          name: key,
                          type: QuestionnaireResponseItemType[
                            upperCase(getField(key).type)
                          ],
                        }),
                      ),
                    title: story.title ?? '',
                  },
                },
              })
              const questionnaireResponse =
                result.data?.patientAppUpsertQuestionnaireResponse
              const items = questionnaireResponse?.items ?? []
              setQuestionnaireResponseItems(items)
              return flattenQuestionnaireResponse(items)
            }}
            onClose={() => setStoryPlayerOpen(false)}
          />
          <ConsentDialog />
          <AppointmentDialog />
          <DocumentDialog />
        </>
      )}
      <SessionExpiredDialog
        onClose={logout}
        open={sessionIsExpired}
      />
      <SessionInactiveDialog
        onConfirm={logout}
        onClose={() => setSessionIsInactive(false)}
        open={sessionIsInactive}
      />
      <KioskSpeedDial />
      <PendoScript />
    </ThemeProvider>
  )
}
