import React, { useContext, useEffect, useState } from 'react'
import useDialog from 'hooks/useDialog'
import {
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import {
  Alert,
  AlertColor,
  Box,
  Button,
  DialogActions,
  DialogContent,
  InputLabel,
  Paper,
  Snackbar,
  Stack,
} from '@mui/material'
import useAppointmentHashParams from 'hooks/useAppointmentHashParams'
import { useAuth } from '@frontegg/react'
import LoadingBackdrop from './LoadingBackdrop'
import {
  PatientAppCareStep,
  TodoCompleteActionType,
  useTodoCompleteActionMutation,
} from '__generated__/graphql'
import { formatDateTime } from '@shared/utils/DateUtil'
import { AppContext } from 'components/AppContext'
import { formatTel } from '@shared/utils/TelUtil'
import TextField from 'components/TextField'
import { addWeeks, isAfter, parseISO } from 'date-fns'
import { StepAccordion } from 'pages/care-plan'
import CareStepModel from 'models/CareStep'
import {
  PatientTodoData,
  PatientTodoMetadata,
} from '@shared/constants'

const { COMPLETE_APPOINTMENT } = TodoCompleteActionType

type SnackBarData = {
  message: string
  severity: AlertColor
}

export default function AppointmentDialog() {
  const { patient, fetchTodos, todos, todosLoading } =
    useContext(AppContext)
  const accountName = patient?.account?.name ?? ''
  const clinicalSupportPhone =
    patient?.account?.settings?.clinicalSupportPhone
  const [dialogProps, { setOpen: setDialogOpen }] = useDialog(
    'appointmentDialog',
  )
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackBarData, setSnackBarData] = useState<SnackBarData>({
    message: '',
    severity: 'success',
  })
  const handleClose = () => {
    dialogProps.onClose()
    // Clear hash and related state after dialog close.
    setTimeout(() => {
      window.location.hash = ''
    }, 300)
  }
  const { isAuthenticated } = useAuth()
  const { patientTodoId } = useAppointmentHashParams({
    // Require authenticated user to enable appointment hash params.
    disabled: !isAuthenticated,
  })
  const patientTodo = todos?.find(({ id }) => id === patientTodoId)
  const patientTodoData = (patientTodo?.data ?? {}) as PatientTodoData
  const careStepProvider = (patientTodoData.careStepProvider ??
    {}) as PatientTodoData['careStepProvider']
  const { patientCareStepId } = (patientTodo?.metadata ??
    {}) as PatientTodoMetadata
  const carePlans = patient?.carePlans || []
  const carePlanSteps = carePlans.flatMap(
    (carePlan) => carePlan?.steps ?? [],
  )
  const careStep =
    carePlanSteps.find(({ id }) => id === patientCareStepId) ??
    ({} as PatientAppCareStep)
  const [
    todoCompleteActionMutation,
    { loading: todoCompleteActionLoading },
  ] = useTodoCompleteActionMutation()

  useEffect(() => {
    // Only open dialog if patientTodoId exists.
    setDialogOpen(!!patientTodoId)
  }, [patientTodoId, setDialogOpen])

  return (
    <>
      <LoadingBackdrop open={todosLoading && !!patientTodoId} />
      {!todosLoading && (
        <EnhancedDialog
          {...dialogProps}
          fullWidth
          maxWidth="xs"
          scroll="body"
          disableCloseOnBackdropClick
          onClose={handleClose}
        >
          <EnhancedDialogTitle onClose={handleClose}>
            Schedule your appointment
          </EnhancedDialogTitle>
          <DialogContent>
            <Stack sx={{ gap: 2, pb: 2 }}>
              <Stack sx={{ gap: 2 }}>
                <StepAccordion
                  step={{
                    areaOfConcern: careStep.areaOfConcern,
                    category: careStep.category,
                    metadata: careStep.metadata,
                    patientDescription: careStep.patientDescription,
                    priority: careStep.data?.priority,
                    subtitle: new CareStepModel(careStep)
                      .modifiersLabel,
                    title: careStep.name,
                    type: careStep.type,
                  }}
                  sx={{
                    '& .MuiAccordionDetails-root': {
                      pb: 2,
                    },
                    '&:not(:last-of-type)': {
                      borderBottomWidth: 1,
                      borderLeftWidth: 0,
                      borderRadius: 0,
                      borderRightWidth: 0,
                      mx: -3,
                    },
                  }}
                />
                {isAfter(
                  new Date(),
                  parseISO(careStep.dueScheduleAt),
                ) && (
                  // Past due date alert.
                  <Alert severity="warning" variant="outlined">
                    {isAfter(
                      new Date(),
                      addWeeks(parseISO(careStep.dueScheduleAt), 1),
                    )
                      ? 'This appointment is overdue. '
                      : 'This appointment is due now. '}
                    Please schedule to remain on track with your care
                    plan.
                  </Alert>
                )}
                {careStep.dueScheduleAt && (
                  <TextField
                    label="Due Date"
                    value={formatDateTime(
                      careStep.dueScheduleAt,
                      false,
                      'E, PP',
                    )}
                    helperText="Schedule appointment on or after this date"
                    asDisplay
                  />
                )}
                {careStepProvider.address && (
                  <Stack>
                    <InputLabel>Location</InputLabel>
                    {careStepProvider.name && (
                      <>
                        {[
                          careStepProvider.name?.namePrefix,
                          careStepProvider.name?.firstName,
                          careStepProvider.name?.lastName,
                        ].join(' ')}
                        <br />
                      </>
                    )}
                    {careStepProvider.address && (
                      <>
                        {careStepProvider.address?.line1 && (
                          <>
                            {careStepProvider.address?.line1}
                            <br />
                          </>
                        )}
                        {careStepProvider.address?.line2 && (
                          <>
                            {careStepProvider.address?.line2}
                            <br />
                          </>
                        )}
                        {careStepProvider.address?.city},{' '}
                        {careStepProvider.address?.state}{' '}
                        {careStepProvider.address?.postalCode}
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Stack sx={{ gap: 2, typography: 'body2' }}>
                  {careStepProvider.phoneNumber && (
                    <Box>
                      For scheduling please call{' '}
                      <Box
                        component="a"
                        href={`tel:${careStepProvider.phoneNumber}`}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {formatTel(careStepProvider.phoneNumber)}
                      </Box>
                      .
                    </Box>
                  )}
                  {careStepProvider.phoneNumber && (
                    <Box>
                      If you are having trouble scheduling, or need
                      other help with your appointment, please call{' '}
                      {accountName} at{' '}
                      {clinicalSupportPhone && (
                        <Box
                          component="a"
                          href={`tel:${clinicalSupportPhone}`}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {formatTel(clinicalSupportPhone)}
                        </Box>
                      )}
                      {!clinicalSupportPhone && (
                        <Box
                          component="span"
                          sx={{ color: 'error.main' }}
                        >
                          &#123;accountSettings.clinicalSupportPhone&#125;
                        </Box>
                      )}
                      .
                    </Box>
                  )}
                  {!careStepProvider.phoneNumber && (
                    <Box>
                      You should have received the scheduling
                      instructions at your appointment. If you do not
                      have this information, or need other help with
                      your appointment, please call {accountName} at{' '}
                      {clinicalSupportPhone && (
                        <Box
                          component="a"
                          href={`tel:${clinicalSupportPhone}`}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {formatTel(clinicalSupportPhone)}
                        </Box>
                      )}
                      {!clinicalSupportPhone && (
                        <Box
                          component="span"
                          sx={{ color: 'error.main' }}
                        >
                          &#123;accountSettings.clinicalSupportPhone&#125;
                        </Box>
                      )}
                      .
                    </Box>
                  )}
                </Stack>
              </Paper>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="text"
              color="secondary"
              disabled={todoCompleteActionLoading}
              onClick={handleClose}
            >
              I&apos;ll do this later
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={todoCompleteActionLoading}
              onClick={async () => {
                await todoCompleteActionMutation({
                  variables: {
                    todoCompleteActionInput: {
                      completeActionData: {
                        patientTodoId: patientTodo?.id,
                      },
                      completeActionType: COMPLETE_APPOINTMENT,
                    },
                  },
                })
                setSnackBarData({
                  message: 'Scheduled appointment successfully.',
                  severity: 'success',
                })
                setSnackbarOpen(true)
                handleClose()
                fetchTodos()
              }}
            >
              Scheduled
            </Button>
          </DialogActions>
        </EnhancedDialog>
      )}
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        autoHideDuration={6000}
        onClose={(e, reason) => {
          if (reason === 'timeout') {
            setSnackbarOpen(false)
          }
        }}
      >
        <Alert severity={snackBarData.severity}>
          {snackBarData.message}
        </Alert>
      </Snackbar>
    </>
  )
}
